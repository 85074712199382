import { useContext, useState } from 'react';
import { Button, Table, showDialogConfirm } from '@octano/global-ui';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { CourseFormContext } from '../providers/CourseProvider';
import { useColumnsCompatibilities } from './useColumnsCompatibilities';
import { SectionTitle } from '../../../../components/text';
import { CourseFormFields, CourseFormMode } from '../types';
import TableEmptyContent from '../../../../components/text/TableEmptyContent';
import ModalAddCompatibility from './ModalAddCompatibility';

const prefix = 'courses.update';

const CourseCompatibilitiesSection = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext<CourseFormFields>();

  const { mode } = useContext(CourseFormContext);

  const compatibilities = watch('compatibilities') || [];

  const [modalCompatibilitiesOpen, setModalCompatibilitiesOpen] =
    useState<boolean>(false);

  const handelConfirmDelete = (index: number) => {
    handleDeleteCompatibility(index);
  };

  const onDeleteCompatibility = (index: number) => {
    showDialogConfirm({
      icon: {
        name: 'warning',
        color: 'warning',
      },
      title: t(`${prefix}.removeCompatibilityTitle`),
      subtitle: '',
      btnConfirm: {
        text: t(`common.actions.confirm`),
        onConfirm: () => {
          handelConfirmDelete(index);
        },
      },
      btnClose: {
        text: t(`common.actions.cancel`),
      },
    });
  };

  const { columns: columnsCompatibilities, handleDeleteCompatibility } =
    useColumnsCompatibilities({
      mode,
      onDeleteCompatibility,
    });

  return (
    <>
      <Row>
        <Col
          xs={12}
          className="d-flex justify-content-between align-items-center p-4"
        >
          <SectionTitle text={t(`${prefix}.compatibilities`)} />
          {mode !== CourseFormMode.WATCH && (
            <Button
              icon="plus"
              onClick={() => setModalCompatibilitiesOpen(true)}
              size="md"
              text={t(`${prefix}.addCompatibility`)}
            />
          )}
        </Col>
        <Col xs={12}>
          <Table
            columns={columnsCompatibilities}
            data={compatibilities}
            noResultsText={
              <TableEmptyContent
                title={t(`${prefix}.titleEmptyCompatibilities`)}
                subtitle={t(
                  `${prefix}.${
                    mode === CourseFormMode.WATCH
                      ? 'watchBodyEmptyCompatibilities'
                      : 'bodyEmptyCompatibilities'
                  }`,
                )}
              />
            }
          />
        </Col>
      </Row>

      <ModalAddCompatibility
        isOpen={modalCompatibilitiesOpen}
        toggle={() => setModalCompatibilitiesOpen(false)}
      />
    </>
  );
};

export default CourseCompatibilitiesSection;
