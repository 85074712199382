import { Button, DateInput, Select, SelectOptionType } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import RemoteSearchSelect from '../../../components/form/RemoteSearchSelect';
import { useSearchResponsible } from '../../../hooks/remoteSearchSelect/useSearchResponsible';
import useSelectOptions from '../../../hooks/useSelectOptions';
import { useValidations } from '../../../hooks/useValidations';
import { PeriodType } from '../../../types/sectionMaintainerTypes';
import { getSelectedOption } from '../../../utils/selectFormat';
import { ScheduleSectionForm } from './ModalMaintainerSchedule';
import { ScheduleList } from './SectionForm';

type Props = {
  sectionId: number | string;
  action: 'Update' | 'Create';
  activityOptions: SelectOptionType[];
  campusOptions: SelectOptionType[];
  moduleOptions: SelectOptionType[];
  responsibleOptions: SelectOptionType[];
  onClose: () => void;
  period?: PeriodType;
  schedule?: ScheduleList;
};

const MaintainerScheduleFormFields = ({
  sectionId,
  action,
  activityOptions,
  campusOptions,
  moduleOptions,
  onClose,
  period,
  schedule,
}: Props) => {
  const prefix = 'sectionsMaintainer.update.modalClass';
  const { t } = useTranslation();
  const { control, watch, setValue, trigger } =
    useFormContext<ScheduleSectionForm>();
  const { msgValidations, validateBeforeDate } = useValidations();
  const [isLoaded, setIsLoaded] = useState(false);
  const { daysOptions } = useSelectOptions();
  const [filteredClassrooms, setFilteredClassrooms] = useState<
    SelectOptionType[]
  >([]);
  const searchResponsible = useSearchResponsible(sectionId);

  const [endDate, selectedCampus, endModule] = watch([
    'endDate',
    'campus',
    'endModule',
  ]);

  const getFilteredClassrooms = (classrooms: any[]) => {
    const filtered: SelectOptionType[] = [];
    classrooms.forEach((classroom: any) => {
      filtered.push({
        value: classroom.id,
        label: classroom.name,
      });
    });
    return filtered;
  };

  /**
   * Al seleccionar sede se actualiza el listado salas asociadas a ésta
   */
  useEffect(() => {
    let filtered: SelectOptionType[] = [];
    if (selectedCampus?.classrooms) {
      filtered = [...getFilteredClassrooms(selectedCampus.classrooms)];
    }
    setFilteredClassrooms(filtered);
  }, [selectedCampus]);

  // Al cambiar el modulo de término se vuelve a validar el módulo de inicio
  useEffect(() => {
    if (endModule) {
      trigger('startModule');
    }
  }, [endModule, trigger]);

  // Al cambiar la fecha de término se vuelve a validar la fecha de inicio
  useEffect(() => {
    if (endDate) {
      trigger('startDate');
    }
  }, [endDate, trigger]);

  // Al cambiar o borrar el campus seleccionado
  // se elimina la selección de sala
  useEffect(() => {
    setValue('classroom', null);
  }, [selectedCampus, setValue]);

  // Si existe clase seleccionada se setean las opciones del select
  // y se calcula la opción seleccionada
  const defaultClassroom = useMemo(() => {
    let filtered: SelectOptionType[] = [];
    if (schedule?.campus) {
      const defaultCampus = campusOptions.find(
        (campus) => campus.value === schedule.campus?.value,
      );
      if (defaultCampus?.classrooms) {
        filtered = [...getFilteredClassrooms(defaultCampus.classrooms)];
      }
    }
    setFilteredClassrooms(filtered);
    return getSelectedOption(schedule?.classroom?.value, filtered);
  }, [schedule, campusOptions]);

  useEffect(() => {
    // Carga la sala por defecto solo si ya se seleccionó el campus por defecto
    // y no ha sido cargada anteriormente
    if (defaultClassroom && selectedCampus && !isLoaded) {
      setIsLoaded(true);
      setValue('classroom', defaultClassroom);
    }
  }, [defaultClassroom, setValue, isLoaded, selectedCampus]);

  const isUpdate = action === 'Update';

  return (
    <>
      <Row className="pb-2">
        <Col xs={12} lg={4}>
          <Select
            label={t(`${prefix}.activity`)}
            name="activity"
            options={activityOptions}
            control={control}
            rules={{ required: msgValidations.required }}
            defaultValue={getSelectedOption(
              schedule?.activity.value,
              activityOptions,
            )}
            shouldUnregister
          />
        </Col>
        <Col xs={12} lg={4}>
          <Select
            label={t(`${prefix}.campus`)}
            name="campus"
            options={campusOptions}
            control={control}
            defaultValue={getSelectedOption(
              schedule?.campus?.value,
              campusOptions,
            )}
            shouldUnregister
          />
        </Col>
        <Col xs={12} lg={4}>
          <Select
            label={t(`${prefix}.classroom`)}
            name="classroom"
            options={filteredClassrooms}
            control={control}
            defaultValue={defaultClassroom}
            shouldUnregister
          />
        </Col>
      </Row>
      <Row className="pb-2">
        <Col xs={12} lg={4}>
          <Select
            label={t(`${prefix}.day`)}
            name="day"
            options={daysOptions}
            control={control}
            rules={{
              required: msgValidations.required,
            }}
            defaultValue={getSelectedOption(schedule?.day.value, daysOptions)}
            shouldUnregister
            disabled={isUpdate}
          />
        </Col>
        <Col xs={12} lg={4}>
          <Select
            label={t(`${prefix}.startModule`)}
            name="startModule"
            options={moduleOptions}
            control={control}
            rules={{
              required: msgValidations.required,
              validate: (value: SelectOptionType) => {
                if (value && endModule) {
                  const indexStart = moduleOptions.findIndex(
                    (mod) => mod.value === value.value,
                  );
                  const indexEnd = moduleOptions.findIndex(
                    (mod) => mod.value === endModule.value,
                  );
                  if (
                    indexStart >= 0 &&
                    indexEnd >= 0 &&
                    indexStart > indexEnd
                  ) {
                    return msgValidations.inValidBeforeModule;
                  }
                }
                return undefined;
              },
            }}
            defaultValue={getSelectedOption(
              schedule?.startModule.value,
              moduleOptions,
            )}
            shouldUnregister
          />
        </Col>
        <Col xs={12} lg={4}>
          <Select
            label={t(`${prefix}.endModule`)}
            name="endModule"
            options={moduleOptions}
            control={control}
            rules={{ required: msgValidations.required }}
            defaultValue={getSelectedOption(
              schedule?.endModule?.value,
              moduleOptions,
            )}
            shouldUnregister
          />
        </Col>
      </Row>
      <Row className="pb-2">
        <Col xs={12} lg={4}>
          <DateInput
            label={t(`${prefix}.startDate`)}
            name="startDate"
            control={control}
            rules={{
              required: msgValidations.required,
              validate: validateBeforeDate(endDate),
            }}
            minDate={
              period?.startDate ? dayjs(period.startDate).toDate() : undefined
            }
            maxDate={
              period?.endDate ? dayjs(period.endDate).toDate() : undefined
            }
            defaultValue={
              schedule?.startDate
                ? dayjs(schedule.startDate).toDate()
                : undefined
            }
            shouldUnregister
          />
        </Col>
        <Col xs={12} lg={4}>
          <DateInput
            label={t(`${prefix}.endDate`)}
            name="endDate"
            control={control}
            rules={{ required: msgValidations.required }}
            minDate={
              period?.startDate ? dayjs(period.startDate).toDate() : undefined
            }
            maxDate={
              period?.endDate ? dayjs(period.endDate).toDate() : undefined
            }
            defaultValue={
              schedule?.endDate ? dayjs(schedule.endDate).toDate() : undefined
            }
            shouldUnregister
          />
        </Col>
        <Col xs={12} lg={4}>
          <RemoteSearchSelect
            name="responsible"
            label={t(`${prefix}.responsible`)}
            filterOptions={searchResponsible}
            control={control}
            defaultValue={{
              value: schedule?.responsible?.account?.id,
              label: schedule?.responsible?.label,
            }}
            shouldUnregister
          />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col xs={12} className="pb-2 text-center">
          {watch('campus') && !watch('classroom') && (
            <p className="text-danger">{t(`${prefix}.obs`)}</p>
          )}
        </Col>
        <Col xs={12} lg={8} xl={7} className="mx-auto">
          <Row>
            <Col xs={12} lg={6} className="pb-2 pb-lg-0">
              <Button
                text={t('common.actions.close')}
                outlined
                onClick={() => {
                  setIsLoaded(false);
                  onClose();
                }}
                fullwidth
              />
            </Col>
            <Col xs={12} lg={6}>
              <Button
                type="submit"
                text={t('common.actions.confirm')}
                fullwidth
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default MaintainerScheduleFormFields;
