import { ColumnTable, Icon } from '@octano/global-ui';
import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Compatibility } from '../../../../types/courseMaintainerTypes';
import { CourseFormFields, CourseFormMode } from '../types';

export const useColumnsCompatibilities = ({
  mode,
  onDeleteCompatibility,
}: {
  mode: CourseFormMode;
  onDeleteCompatibility: (index: number) => void;
}) => {
  const prefix = 'courses.update';
  const { t } = useTranslation();
  const { setValue, getValues, register } = useFormContext<CourseFormFields>();

  const handleDeleteCompatibility = useCallback(
    (index: number) => {
      const compatibilities = getValues('compatibilities') || [];
      compatibilities.splice(index, 1);
      setValue('compatibilities', compatibilities);
    },
    [getValues, setValue],
  );

  useEffect(() => {
    register('compatibilities');
  }, [register]);

  const columns: ColumnTable<Compatibility>[] = useMemo(() => {
    return [
      {
        columnName: 'shortening',
        width: '30%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.compatibilityShortening`),
      },
      {
        columnName: 'name',
        width: '40%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.compatibilityName`),
      },
      {
        columnName: 'credits',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.compatibilityCredits`),
      },
      {
        columnName: 'id',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.compatibilityDelete`),
        cellFormat: ({ index }) => (
          <div
            className={mode !== CourseFormMode.WATCH ? 'cursor-pointer' : ''}
            onClick={() => {
              if (mode !== CourseFormMode.WATCH) onDeleteCompatibility(index);
            }}
          >
            <Icon
              size="15"
              name="trash"
              color={mode === CourseFormMode.WATCH ? 'disabled' : 'primary'}
            />
          </div>
        ),
      },
    ];
  }, [t, onDeleteCompatibility, mode]);

  return { columns, handleDeleteCompatibility };
};
