import { Modal, SelectOptionType } from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import { PeriodType } from '../../../types/sectionMaintainerTypes';
import { SectionScheduleGetResponse } from '../types';
import { formatModuleLabel } from '../utils';
import MaintainerScheduleFormFields from './MaintainerScheduleFormFields';
import { ScheduleList } from './SectionForm';

type ModalMaintainerScheduleProps = {
  sectionId: number;
  isModalOpen: boolean;
  closeModal: () => void;
  onSave: (values: ScheduleSectionForm, index?: number) => void;
  scheduleIndex?: number;
  schedule?: ScheduleList;
  activities: SectionScheduleGetResponse['activities'];
  campuses: SectionScheduleGetResponse['campuses'];
  modules: SectionScheduleGetResponse['timeModules'];
  responsibles: SectionScheduleGetResponse['teachers'];
  period?: PeriodType;
};

export type ScheduleSectionForm = {
  activity: SelectOptionType;
  campus: SelectOptionType | null;
  classroom: SelectOptionType | null;
  day: SelectOptionType;
  startModule: SelectOptionType;
  endModule: SelectOptionType;
  startDate?: string;
  endDate?: string;
  responsible?: SelectOptionType;
};

const ModalMaintainerSchedule = ({
  sectionId,
  isModalOpen,
  closeModal,
  onSave,
  scheduleIndex,
  schedule,
  activities,
  campuses,
  modules,
  responsibles,
  period,
}: ModalMaintainerScheduleProps) => {
  const prefix = 'sectionsMaintainer.update.modalClass';
  const { t } = useTranslation();

  const methods = useForm<ScheduleSectionForm>();
  const { handleSubmit } = methods;
  const action = scheduleIndex !== undefined ? 'Update' : 'Create';

  const onSubmit = useCallback(
    (values: ScheduleSectionForm) => {
      onSave(values, scheduleIndex);
    },
    [onSave, scheduleIndex],
  );

  /**
   * Se obtienen las opciones de los selects y
   * la información de periodo para realizar las validaciones de fechas
   */
  const options = useMemo(() => {
    return {
      activityOptions: activities.map((act) => {
        return {
          value: act.id,
          label: act.name,
        };
      }),
      campusOptions: campuses.map((campus) => {
        return {
          value: campus.id,
          label: campus.name,
          classrooms: campus.classrooms,
        };
      }),
      moduleOptions: modules.map((mod) => {
        return {
          value: mod.id,
          label: formatModuleLabel(mod, 'both'),
        };
      }),
      responsibleOptions: responsibles.map((prof) => {
        return {
          value: prof.account?.id ?? 0,
          label: prof.account?.fullName || '',
          account: prof.account,
        };
      }),
    };
  }, [activities, campuses, modules, responsibles]);

  return (
    <Modal isOpen={isModalOpen} toggle={closeModal} size="xl">
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <p className="fs-22 text-dark text-center mb-4">
            {t(`${prefix}.title${action}`)}
          </p>
          <Row>
            <Col xs={12} lg={10} className="mx-auto text-center">
              <p className="fs-16 text-light mb-5">
                {t(`${prefix}.subtitle${action}`)}
              </p>
            </Col>
          </Row>
          <MaintainerScheduleFormFields
            sectionId={sectionId}
            action={action}
            activityOptions={options.activityOptions}
            campusOptions={options.campusOptions}
            moduleOptions={options.moduleOptions}
            responsibleOptions={options.responsibleOptions}
            onClose={closeModal}
            period={period}
            schedule={schedule}
          />
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default ModalMaintainerSchedule;
